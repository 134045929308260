<template>
    <svg class="block" fill="none" :height="height" :width="width" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.001 23.927a8.731 8.731 0 0 1-2.91-.558 5.9 5.9 0 0 1-2.127-1.383A5.796 5.796 0 0 1 1.58 19.86a8.94 8.94 0 0 1-.558-2.911C.966 15.67.95 15.259.95 12s.016-3.671.073-4.949c.016-.994.21-1.98.558-2.91a5.9 5.9 0 0 1 1.383-2.128A5.9 5.9 0 0 1 5.09.631 8.733 8.733 0 0 1 8.001.073C9.28.016 9.691 0 12.951 0c3.258 0 3.67.016 4.948.073a8.72 8.72 0 0 1 2.911.558 5.9 5.9 0 0 1 2.127 1.382A5.796 5.796 0 0 1 24.32 4.14c.347.93.541 1.917.557 2.911.057 1.278.073 1.69.073 4.949s-.016 3.671-.073 4.949a8.939 8.939 0 0 1-.557 2.91 5.9 5.9 0 0 1-1.383 2.127 5.796 5.796 0 0 1-2.127 1.383 8.939 8.939 0 0 1-2.911.558c-1.278.057-1.69.073-4.949.073s-3.671-.016-4.949-.073Zm.097-21.695a6.608 6.608 0 0 0-2.231.412 3.82 3.82 0 0 0-1.383.898 3.752 3.752 0 0 0-.898 1.383 6.571 6.571 0 0 0-.412 2.231c-.057 1.27-.073 1.642-.073 4.852 0 3.21.016 3.582.073 4.852.008.76.145 1.512.412 2.232.194.517.502.994.898 1.382a3.74 3.74 0 0 0 1.383.898 6.573 6.573 0 0 0 2.231.412c1.27.057 1.642.073 4.852.073 3.21 0 3.582-.008 4.852-.073a6.61 6.61 0 0 0 2.232-.412 3.752 3.752 0 0 0 1.383-.898c.396-.388.703-.857.897-1.382a6.572 6.572 0 0 0 .413-2.232c.056-1.27.072-1.642.072-4.852 0-3.21-.008-3.582-.072-4.852a6.609 6.609 0 0 0-.413-2.231 3.82 3.82 0 0 0-.898-1.383 3.752 3.752 0 0 0-1.382-.898 6.571 6.571 0 0 0-2.232-.412c-1.261-.057-1.642-.073-4.852-.073-3.21 0-3.582.008-4.852.073ZM6.998 12a5.963 5.963 0 0 1 5.952-5.96 5.951 5.951 0 0 1 5.952 5.952 5.963 5.963 0 0 1-5.952 5.96C9.66 17.96 7 15.291 7 12Zm2.087 0a3.865 3.865 0 1 0 7.73 0 3.865 3.865 0 0 0-7.73 0Zm8.75-6.372A1.49 1.49 0 0 1 19.33 4.14c.825 0 1.496.671 1.496 1.488 0 .825-.663 1.488-1.488 1.488a1.498 1.498 0 0 1-1.504-1.488c0-.008 0-.008 0 0Z" :fill="fill" />
    </svg>
</template>
<script>
export default {
    props: {
        fill: {
            type: String,
            default: '#253139',
        },
        height: {
            type: String,
            default: "20px",
        },
        width: {
            type: String,
            default: "21px",
        },
    }
}
</script>
